import { PageProps, graphql } from "gatsby";
import React from "react";

import { BlocksContent } from "@components/global";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import Layout from "@shared/layout";
import ConversionBlock from "@components/conversions/conversionBlock";

interface Props extends PageProps {
  data: Query;
}

function Conversions({ data }: Props) {
  if (data == null || data.sanityConversions == null) {
    return null;
  }

  const { categoryPreview, conversionHero, conversionsBlocks, seo } = data.sanityConversions;

  return (
    <div>
      <SEO seoData={seo} />
      <Layout>
        <Container
          width="65%"
          tabletWidth="80%"
          margin="220px auto 140px auto"
          tabletMargin="220px auto 40px auto"
          flexDirection="column"
        >
          {conversionHero && <BlocksContent blocks={conversionHero._rawColumnContent} />}
          {conversionsBlocks && (
            <Container width="100%" flexWrap="wrap" margin="100px 0 0 0" tabletMargin="40px 0 50px 0">
              {conversionsBlocks.map((block, index) => {
                if (block == null) return null;

                const hasMarginRight = index % 2 === 0 || index === 0;
                return (
                  <Container
                    width="46%"
                    mobileWidth="100%"
                    margin={hasMarginRight ? "0 8% 80px 0" : "0 0 80px 0"}
                    mobileMargin="0"
                    key={block._key}
                  >
                    <ConversionBlock data={block} />
                  </Container>
                );
              })}
            </Container>
          )}
        </Container>
        {categoryPreview && <LearnCategoryPreview data={categoryPreview} />}
      </Layout>
    </div>
  );
}

export default Conversions;

export const query = graphql`
  {
    sanityConversions {
      seo {
        ...sanitySeo
      }
      conversionsBlocks {
        _key
        _type
        title
        textBlock {
          ...sanityColumn
        }
        image {
          ...sanityFullWidthImage
        }
      }
      conversionHero {
        _key
        _type
        note
        _rawColumnContent
      }
      categoryPreview {
        ...sanityLearnCategoryPreview
      }
    }
  }
`;
