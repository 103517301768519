import React from "react";
import styled from "styled-components";

import { BlocksContent, Image } from "@components/global";
import { SanityConversionContent } from "@graphql-types";
import { Container } from "@util/standard";

interface Props {
  data: SanityConversionContent;
}

const TextWrapper = styled.div`
  width: 100%;
  h3 {
    margin-bottom: 0px;
  }
`;

function ConversionBlock({ data }: Props) {
  if (data == null) return null;
  return (
    <TextWrapper>
      <BlocksContent blocks={data.textBlock?._rawColumnContent} />
      <Container margin="30px 0 0 0">
        <Image image={data.image?.asset?.gatsbyImageData} />
      </Container>
    </TextWrapper>
  );
}

export default ConversionBlock;
